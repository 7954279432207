<template>
    <div class="createPromotionBuyoutPrice">
        <el-card shadow="never" style="margin-top: 8px">
            <div style="display: flex; justify-content: space-between; padding-right: 20px">
                <el-steps
                    :active="1"
                    finish-status="success"
                    simple
                    style="margin-left: 14px; width: 300px; color: #1ff680; font-size: 14px"
                >
                    <el-step title="活动设置"></el-step>
                    <el-step title="投放设置" icon=""></el-step>
                </el-steps>

                <el-form>
                    <el-button
                        size="medium"
                        type="primary"
                        @click="onNextStep"
                        v-if="
                            hasPrivilege('menu.promotion.npromotion.edit') ||
                            hasPrivilege('menu.promotion.npromotion.create')
                        "
                        >下一步</el-button
                    >
                </el-form>
            </div>
        </el-card>
        <el-card style="margin-top: 8px" shadow="never">
            <el-form :model="form" inline size="medium" :rules="rules" ref="fm">
                <div style="display: flex">
                    <el-form-item label="活动名称" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                </div>
            </el-form>

            <div class="mb10">
                <span class="font16 bold">活动规则</span>
                <hr class="efHr" style="background-color: #ccc" />
                <div class="flexBtw">
                    <div class="font14 flexBtw">
                        <div style="margin-right: 14px"><span class="clr-pink">* </span><span>选择商品</span></div>
                        <el-form inline :model="form" size="medium" style="display: inline-block">
                            <el-form-item label="价格参考店面" style="width: 1200px; margin: 0">
                                <efn-group-dept-cascader
                                    size="medium"
                                    v-model="organization"
                                    @changeFull="onChangeDept"
                                    :sel-all-dept="false"
                                ></efn-group-dept-cascader>
                                <el-button type="text" class="left40" @click="onPickUpSku"
                                    >+选择参加活动的商品
                                </el-button>
                                <span class="clr-textGray" style="margin-left: 40px"
                                    >切换机构组将清空数据，下表零售价为价格参考店面的商品零售价格</span
                                >
                            </el-form-item>
                        </el-form>
                    </div>
                    <div>
                        <el-button
                            type="text"
                            style="float: right"
                            class="clr-pink"
                            @click="meta.goodsInPromotion = [{}]"
                            >移除全部
                        </el-button>
                    </div>
                </div>
                <span v-if="!check.goods.valid" style="color: red">* {{ check.goods.message }}</span>
            </div>

            <efn-table :data="meta.goodsInPromotion" size="medium" border>
                <el-table-column type="index" label="No."></el-table-column>
                <el-table-column width="220" label="活动商品" prop="sku.name">
                    <template slot-scope="scope">
                        <div v-if="scope.$index < meta.goodsInPromotion.length - 1">
                            {{ scope.row['sku'].name }}
                        </div>
                        <div v-else>
                            <efn-good-pop-search
                                size="mini"
                                :sku-type="0"
                                v-model="scope.row.skuName"
                                :deptCode="form.deptCode"
                                @select="handleSelectPopTable"
                                clearable
                            ></efn-good-pop-search>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="140" label="规格" prop="sku.specs">
                    <template slot-scope="scope">
                        <div v-if="scope.$index < meta.goodsInPromotion.length - 1">
                            {{ scope.row['sku'].specs }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="120" label="零售价" prop="price">
                    <template slot-scope="scope">
                        {{ scope.row.retailPrice | money }}
                    </template>
                </el-table-column>
                <el-table-column label="参考店面" width="140" prop="price">
                    <template slot-scope="scope">
                        <span>{{ scope.row.deptName }}</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="500"></el-table-column>
                <el-table-column label="操作" fixed="right" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.$index < meta.goodsInPromotion.length - 1">
                            <el-button type="text" class="clr-pink" @click="onLineDelete(scope.$index)">移除</el-button>
                        </span>
                    </template>
                </el-table-column>
            </efn-table>

            <div style="margin-top: 30px" class="flexBtw">
                <div>
                    <span class="font14"><span class="clr-pink">* </span>活动规则</span>
                    <el-button type="text" style="margin-left: 40px" @click="onAddFavour">+添加活动规则</el-button>
                    <span style="margin: auto 20px" class="clr-pink" v-if="!check.favour.valid">
                        {{ check.favour.message }}
                    </span>
                    <span class="clr-textGray font14" style="margin-left: 40px"
                        >购买任意件数以上商品按下表规则统一一口价</span
                    >
                </div>
                <div>
                    <el-button type="text" style="margin-left: 40px" class="clr-pink" @click="form.favour = [{}]"
                        >移除全部
                    </el-button>
                </div>
            </div>

            <efn-table size="medium" border :data="form.favour">
                <el-table-column label="活动商品买满数量(个/件)" width="180px" align="center">
                    <template slot-scope="scope">
                        <ef-price-input :precision="0" v-model="scope.row.count"></ef-price-input>
                    </template>
                </el-table-column>
                <el-table-column label="一口价(元)" width="120px" align="center">
                    <template slot-scope="scope">
                        <ef-price-input v-model="scope.row.price"></ef-price-input>
                    </template>
                </el-table-column>
                <el-table-column></el-table-column>
                <el-table-column label="操作" fixed="right" width="140px">
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.$index > 0"
                            type="text"
                            class="clr-pink"
                            @click="onLineDeleteFavour(scope.$index)"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </efn-table>
        </el-card>
        <pick-up-goods ref="pickupGoods" @queryGoods="handleSelectSkuOk"></pick-up-goods>
    </div>
</template>

<script>
import PickUpGoods from 'components/PickUpGoods';
import EfnGoodPopSearch from 'components/newStyle/EfnGoodPopSearch';
import EfPriceInput from 'components/EfPriceInput';
import MoneyUtils from 'js/MoneyUtils';
import EfnGroupDeptCascader from 'components/newStyle/EfnGroupDeptCascader';
import EfnTable from 'components/newStyle/EfnTable';

export default {
    name: 'CreatePromotionBuyoutPrice',
    components: { EfnTable, EfnGroupDeptCascader, EfPriceInput, EfnGoodPopSearch, PickUpGoods },
    props: {
        editPromotionCode: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            form: {
                code: '',
                name: '',
                groupCode: '',
                deptCode: '',
                deptName: '',
                //活动5商品同排除商品列表
                allGoodsFlag: false,
                allGoodsCountType: 1,
                rule: 5,
                goods: [{}],
                favour: [
                    {
                        count: 2,
                        price: 0,
                    },
                ],
            },
            meta: {
                goodsInPromotion: [{ code: '' }],
            },
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 5, max: 40, message: '长度在 5 到 40 个字', trigger: 'blur' },
                ],
            },
            check: {
                favour: {
                    message: '',
                    valid: true,
                },
                goods: {
                    message: '',
                    valid: true,
                },
            },
            url: {
                makeCode: '/npromotion/code',
                goodsBySkuAndDept: '/goods/basic/listBySkuCodes',
                save: '/npromotion/detail',
            },
        };
    },
    computed: {
        organization: {
            get() {
                return [this.form.groupCode, this.form.deptCode];
            },
            set(data) {
                if (this.form.groupCode !== data[0]) {
                    this.meta.goodsInPromotion = [{}];
                }
                this.form.groupCode = data[0];
                this.form.deptCode = data[1];
            },
        },
    },
    mounted() {
        const _this = this;
        if (this.editPromotionCode) {
            //编辑
            this.$http.get(this.url.save + '/' + this.editPromotionCode).then((rsp) => {
                _this.form = rsp.data.data;
                _this.form.code = _this.editPromotionCode;
                _this.refreshGoodsList();
                _this.refreshFavour();
            });
        } else {
            //新建
            this.$http.get(this.url.makeCode).then((rsp) => (_this.form.code = rsp.data));
        }
    },
    methods: {
        async onNextStep() {
            const _this = this;
            const rst = await _this.$refs.fm.validate().catch(() => {});
            if (!rst) {
                return;
            }
            // 校验 goods 存在且 同sku count不重复
            this.check.goods.valid = _this.checkGoods();
            if (!_this.check.goods.valid) {
                return false;
            }
            this.check.favour.valid = _this.checkFavour();
            if (!_this.check.favour.valid) {
                return false;
            }

            const _source = _this.meta.goodsInPromotion.slice();
            _source.splice(_source.length - 1, 1);
            _this.form.goods = _source.map((s) => {
                const g = {};
                g.skuCode = s.sku.code;
                g.deptName = s.deptName;
                g.deptCode = s.deptCode;
                g.oriPrice = s.retailPrice;
                return g;
            });

            const _tempFavour = JSON.parse(JSON.stringify(this.form.favour));
            this.form.favour = this.form.favour.map((f) => {
                f.price = MoneyUtils.moneyToDb(f.price);
                return f;
            });
            this.loadingStart();
            _this.$http
                .post(_this.url.save + '/' + _this.form.code, _this.form, {})
                .then(() => {
                    _this.loadingStop();
                    _this.$message.success('保存成功');
                    _this.$router.push({
                        name: 'menu.promotion.npromotion.create.launch',
                        params: { promotionCode: this.form.code },
                    });
                    _this.$store.commit('delTabRoute', 'menu.promotion.npromotion.create.buyoutPrice');
                })
                .catch(() => {
                    _this.loadingStop();
                    _this.form.favour = _tempFavour;
                });
        },

        checkFavour() {
            //指定商品活动， 不校验favour

            if (!this.form.favour.length) {
                this.check.favour.message = '至少添加一个活动规则';
                return false;
            }
            const s = new Set();
            for (const i in this.form.favour) {
                const g = this.form.favour[i];
                if (i == this.form.favour.length - 1) {
                    break;
                }
                if (!g.count || g.count <= 1) {
                    this.check.favour.message = '购买商品数量必填且不小于2';
                    return false;
                }
                if (!g.price) {
                    this.check.favour.message = '一口价必填且不能为0';
                    return false;
                }
                if (s.has(g.count)) {
                    this.check.favour.message = '商品购买数量不能重复';
                    return false;
                } else {
                    s.add(g.count);
                }
            }
            this.check.favour.message = '';
            return true;
        },
        checkGoods() {
            //指定商品活动，仅校验goods
            if (!this.meta.goodsInPromotion.length || this.meta.goodsInPromotion.length < 3) {
                this.check.goods.message = '至少添加两个活动商品';
                return false;
            }
            this.check.goods.message = '';
            return true;
        },
        onAddFavour() {
            this.form.favour.push({ price: null, count: null });
        },
        onLineDeleteFavour(idx) {
            this.form.favour.splice(idx, 1);
        },
        onLineDelete(idx) {
            this.meta.goodsInPromotion.splice(idx, 1);
        },
        onChangeDept(data) {
            this.form.deptName = data.deptName;
        },

        onPickUpSku() {
            const _this = this;
            this.$refs.pickupGoods.show(
                {
                    deptCode: _this.form.deptCode,
                },
                _this.form.deptName
            );
        },
        handleSelectSkuOk(data) {
            if (!data.length) {
                return;
            }
            const _d = data.filter((d) => !d.sku.type);
            if (_d.length != data.length) {
                this.$message.info('非标品不能参与活动');
                if (!_d.length) {
                    this.resetPopInput();
                    return;
                }
            }
            data = _d;

            let _array = this.meta.goodsInPromotion;
            const existCodes = _array.filter((a) => a['sku']).map((a) => a['sku']['code']);
            const skuToAdd = data.filter((a) => existCodes.findIndex((code) => code === a.sku.code) < 0);
            if (skuToAdd.length <= 0) {
                this.$message.info('该商品已存在');
                this.resetPopInput();
                return;
            }
            _array.splice(_array.length - 1, 1);
            _array = _array.concat(skuToAdd).concat({ code: '' });
            this.meta.goodsInPromotion = _array;
        },

        handleSelectPopTable(item) {
            this.handleSelectSkuOk([item]);
        },

        resetPopInput() {
            const _array = this.meta.goodsInPromotion;
            const obj = _array[_array.length - 1];
            obj.skuName = '';
            _array.splice(_array.length - 1, 1, obj);
        },

        refreshGoodsList() {
            const _this = this;
            const _array = _this.form.goods.map((r) => {
                return {
                    sku: {
                        code: r.skuCode,
                        specs: r.specs,
                        name: r.goodsName,
                    },
                    count: r.count,
                    retailPrice: r.oriPrice,
                    discount: r.discount,
                    price: MoneyUtils.moneyToYuan(r.price),
                    deptName: r.deptName,
                    deptCode: r.deptCode,
                };
            });
            _array.splice(_array.length, 0, {});
            _this.meta.goodsInPromotion = _array;
        },
        refreshFavour() {
            this.form.favour = this.form.favour.map((f) => {
                f.price = MoneyUtils.moneyToYuan(f.price);
                return f;
            });
        },
    },
};
</script>
